
import { useAccountStore } from "@/stores/account"
import { SsoProvider } from "@evercam/shared/types"
export default {
  props: {
    buttonText: {
      type: String,
      default: () => "",
    },
    ssoProvider: {
      type: String,
      default: () => SsoProvider.Google,
    },
    outlined: {
      type: Boolean,
      default: () => true,
    },
    link: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    signin() {
      const state = Math.random()
        .toString(36)
        .replace(/[^a-z0-9]+/g, "")
      window.localStorage?.setItem("sourceUrlRequestedSso", this.$route.path)
      window.localStorage?.setItem("ssoState", state)
      window.localStorage?.setItem("ssoProvider", this.ssoProvider)
      if (useAccountStore().redirectUrl) {
        window.localStorage?.setItem(
          "postSsoRedirectUrl",
          useAccountStore().redirectUrl
        )
      }
      let providerUrl, clientId, scope

      if (this.ssoProvider === SsoProvider.Google) {
        providerUrl = this.$config.public.googleAuthUrl
        clientId = this.$config.public.googleAuthClientId
        scope = "profile email"
      } else if (this.ssoProvider == SsoProvider.Microsoft) {
        providerUrl = this.$config.public.microsoftAuthUrl
        clientId = this.$config.public.microsoftAuthClientId
        scope = "openid email profile"
      } else if (this.ssoProvider == SsoProvider.Evercam) {
        providerUrl = this.$config.public.evercamAuthUrl
        clientId = this.$config.public.evercamAuthClientId
        scope = "openid email profile"
      }
      if (!providerUrl || !clientId || !scope) {
        return
      }

      window.location.href = `${providerUrl}?response_type=code&client_id=${clientId}&scope=${scope}&redirect_uri=${window.location.origin}&state=${state}`
    },
  },
}
